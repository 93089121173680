import { State } from "./types";
/*
 * define state here
 */
export default (): State => ({
  busCompany: "",
  userProfile: {
    user_id: "",
    email: "",
    root_org: "",
    user_first_name: "",
    user_last_name: "",
    organization: [
      {
        org_no: "",
        org_id: "",
        org_name: "",
        root_org: "",
        bus_company: "",
      },
    ],
    phone_number: "",
  },
  resetPassword: {
    email: "",
  },
});

import { useAxiosTEQ } from "@/services/axios";
import { HTTP_TRIP } from "@/services/enums/httpEnum";
import { TypeTripFilter, TripResponse } from "@/stores/trip/types";
import { AxiosResponse } from "axios";
import { ContactPersonType } from "@/stores/order/types";

/**
 * fetch upcoming trip info
 * @param {TypeTripFilter} payload for trip Filter
 *  @returns {Promise<AxiosResponse<TripResponse>>} - axios response
 */
const fetchUpcomingTrips = (
  payload: TypeTripFilter
): Promise<AxiosResponse<TripResponse>> => {
  return useAxiosTEQ.get(`${HTTP_TRIP.FETCH_UPCOMING_TRIP_LIST}`, {
    headers: {
      showToast: true,
    },
    params: {
      ...payload,
    },
  });
};
/**
 * fetch all trip info
 * @param {TypeTripFilter} payload for trip Filter
 *  @returns {Promise<AxiosResponse<TripResponse>>} - axios response
 */
const fetchAllTrips = (
  payload: TypeTripFilter
): Promise<AxiosResponse<TripResponse>> => {
  return useAxiosTEQ.get(`${HTTP_TRIP.FETCH_ALL_TRIP_LIST}`, {
    headers: {
      showToast: true,
    },
    params: {
      ...payload,
    },
  });
};

/**
 * fetch all trip info
 * @param id
 * @param payload for bus_company
 * @returns axios function
 */
const fetchFixedTripDetails = (id: number, payload: object) => {
  return useAxiosTEQ.get(`${HTTP_TRIP.FETCH_FIXED_TRIP_DETAILS}/${id}`, {
    headers: {
      showToast: true,
    },
    params: {
      ...payload,
    },
  });
};

/**
 * fetch all trip info
 * @param id
 * @param payload for bus_company
 * @returns axios function
 */
const fetchNormalTripDetails = (id: number, payload: object) => {
  return useAxiosTEQ.get(`${HTTP_TRIP.FETCH_NORMAL_TRIP_DETAILS}/${id}`, {
    headers: {
      showToast: true,
    },
    params: {
      ...payload,
    },
  });
};
/**
 *
 * @param {number} trip_id
 * @param  { string} trip_type
 * @param {string} bus_company
 * @param {string} org_id
 * @returns {Promise<AxiosResponse>} - axios response
 */
export const cancelTrip = (
  trip_id: number,
  trip_type: string,
  bus_company: string,
  org_id: number
): Promise<AxiosResponse> => {
  return useAxiosTEQ.post(
    `${HTTP_TRIP.CANCEL_TRIP.replace(
      "{type}",
      trip_type.toLowerCase()
    )}/${trip_id}`,
    null,
    {
      params: {
        bus_company,
        org_ids: JSON.stringify([org_id.toString()]),
      },
    }
  );
};

const updateTripPoc = (
  trip_id: number,
  org_id: string | number,
  trip_type: string,
  bus_company: string,
  payload: ContactPersonType
): Promise<AxiosResponse> => {
  return useAxiosTEQ.post(
    `${HTTP_TRIP.UPDATE_POC.replace(
      "{type}",
      trip_type.toLowerCase()
    )}/${trip_id}`,
    { ...payload },
    {
      params: {
        bus_company,
        org_ids: JSON.stringify([org_id.toString()]),
      },
    }
  );
};

export {
  fetchUpcomingTrips,
  fetchAllTrips,
  fetchFixedTripDetails,
  fetchNormalTripDetails,
  updateTripPoc,
};

import { defineStore } from "pinia";
import state from "./trip.state";
import actions from "./trip.action";
import getters from "./trip.getters";

const useTripStore = defineStore("trip", {
  state,
  actions,
  getters,
});

export default useTripStore;

import { StoreContext, Order, CreateOrderResponse, NewOrder } from "./types.ts";
import useLoaderStore from "../loader/loader.store.ts";
import { RESPONSE_TYPE } from "@/services/enums/httpEnum";
import {
  fetchOrderCount,
  acceptOrder,
  createQuotation,
  fetchOrderList,
  createQuery,
  rejectOrderApi,
} from "@/services/api/orders/orderApi.ts";
import { AxiosResponse } from "axios";
import { TypeFilter } from "types/common/index.ts";
import { showToast } from "@/services/toast/toast.service";

export default {
  /**
   *
   * @param {StoreContext} this -store context
   * @param {TypeFilter} payload -function payload for filtering orders
   * @returns {Promise<Order[]>} - returning all thr Order in the component but may be we it will not required we could send something else there
   */
  async fetchOrderList(
    this: StoreContext,
    payload: TypeFilter
  ): Promise<Order[]> {
    const loader = useLoaderStore();
    loader.changeLoadingStatus({ isLoading: true });
    return fetchOrderList(payload)
      .then((res) => {
        loader.changeLoadingStatus({ isLoading: false });
        const response = res.data;
        if (res.status == 200 && response?.status !== RESPONSE_TYPE.SUCCESS)
          throw Error("API error");

        /*
         * Need to change result into orders from backend
         */
        this.$state.results = response.data.results;
        this.$state.count = response.data.count;
        this.$state.next = response.data.next;
        this.$state.previous = response.data.previous;
        return response.data.results;
      })
      .catch((error) => {
        loader.changeLoadingStatus({ isLoading: false });
        throw error;
      });
  },

  /**
   *
   * @param {StoreContext} this -store context
   * @param {string} bus_company -function payload for filtering orders
   * @param {number[]} org_ids -function payload for filtering orders
   * @returns {Promise<Order[]>} - returning all thr Order in the component but may be we it will not required we could send something else there
   */
  async fetchOrderCount(
    this: StoreContext,
    bus_company: string,
    org_ids: (string | number)[]
  ): Promise<{ quotation_count: number }> {
    const loader = useLoaderStore();
    loader.changeLoadingStatus({ isLoading: true });
    return fetchOrderCount(bus_company, org_ids)
      .then((res) => {
        loader.changeLoadingStatus({ isLoading: false });
        const response = res.data;
        if (res.status == 200 && response?.status !== RESPONSE_TYPE.SUCCESS)
          throw Error("API error");

        return response.data;
      })
      .catch((error) => {
        loader.changeLoadingStatus({ isLoading: false });
        throw error;
      });
  },

  /**
   * Accepts an order.
   * @param {StoreContext} this -store context
   * @param {number} dbOrderId -function payload for filtering orders
   * @param {string} bus_company -function payload for filtering orders
   * @returns {null} - nothing
   */
  async acceptOrder(
    this: StoreContext,
    dbOrderId: number,
    bus_company: string,
    org_ids: (string | number)[]
  ): Promise<AxiosResponse> {
    const loader = useLoaderStore();
    loader.changeLoadingStatus({ isLoading: true });
    return acceptOrder(dbOrderId, bus_company, org_ids)
      .then((res) => {
        loader.changeLoadingStatus({ isLoading: false });
        showToast("success", res.data.message);
        const response = res.data;
        if (res.status == 200 && response?.status !== RESPONSE_TYPE.SUCCESS)
          throw Error("API error");
        return response;
      })
      .catch((error) => {
        loader.changeLoadingStatus({ isLoading: false });
        showToast("error", error.message);
        throw error;
      });
  },

  async createOrderQuotation(
    payload: NewOrder,
    bus_company: string
  ): Promise<CreateOrderResponse> {
    const loader = useLoaderStore();
    loader.changeLoadingStatus({ isLoading: true });
    return createQuotation(payload, bus_company)
      .then((res) => {
        loader.changeLoadingStatus({ isLoading: false });
        showToast("success", res.data.message);
        const response = res.data;
        if (res.status == 200 && response?.status !== RESPONSE_TYPE.SUCCESS)
          throw Error("API error");
        return response;
      })
      .catch((error) => {
        loader.changeLoadingStatus({ isLoading: false });
        showToast("error", error.message);
        throw error;
      });
  },

  async rejectOrder(
    this: StoreContext,
    dbOrderId: number,
    bus_company: string,
    org_ids: (string | number)[]
  ): Promise<AxiosResponse> {
    const loader = useLoaderStore();
    loader.changeLoadingStatus({ isLoading: true });
    return rejectOrderApi(dbOrderId, bus_company, org_ids)
      .then((res) => {
        loader.changeLoadingStatus({ isLoading: false });
        const response = res.data;
        if (res.status == 200 && response?.status !== RESPONSE_TYPE.SUCCESS) {
          throw Error("API error");
        }
        return response;
      })
      .catch((error) => {
        loader.changeLoadingStatus({ isLoading: false });
        throw error;
      });
  },

  async createOrderQuery(
    payload: NewOrder,
    bus_company: string
  ): Promise<CreateOrderResponse> {
    const loader = useLoaderStore();
    loader.changeLoadingStatus({ isLoading: true });
    return createQuery(payload, bus_company)
      .then((res) => {
        loader.changeLoadingStatus({ isLoading: false });
        const response = res.data;
        if (res.status == 200 && response?.status !== RESPONSE_TYPE.SUCCESS) {
          throw Error("API error");
        }
        return response;
      })
      .catch((error) => {
        loader.changeLoadingStatus({ isLoading: false });
        throw error;
      });
  },
};

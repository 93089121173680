import { useAxiosTEQ } from "@/services/axios";
import { HTTP_ORDER } from "@/services/enums/httpEnum";
import {
  CreateOrderResponse,
  NewOrder,
  OrderResponse,
} from "@/stores/order/types";
import { AxiosResponse } from "axios";
import { TypeFilter } from "types/common";

/**
 * fetch upcoming trip info
 * @param {TypeFilter} payload for trip Filter
 *  @returns {Promise<AxiosResponse<OrderResponse>>} - axios response
 */
const fetchOrderList = (
  payload: TypeFilter
): Promise<AxiosResponse<OrderResponse>> => {
  return useAxiosTEQ.get(`${HTTP_ORDER.FETCH_ORDER_LIST}`, {
    params: {
      ...payload,
    },
  });
};

const fetchOrderCount = (
  bus_company: string,
  org_ids: (string | number)[]
): Promise<AxiosResponse> => {
  return useAxiosTEQ.get(`${HTTP_ORDER.ORDER_COUNT}`, {
    headers: {
      showToast: true,
    },
    params: {
      bus_company,
      org_ids: JSON.stringify(org_ids),
    },
  });
};

const acceptOrder = (
  dbOrderId: number,
  bus_company: string,
  org_ids: (string | number)[]
): Promise<AxiosResponse> => {
  return useAxiosTEQ.post(`${HTTP_ORDER.ACCEPT_ORDER}/${dbOrderId}`, null, {
    params: {
      bus_company,
      org_ids: JSON.stringify([org_ids.toString()]),
    },
  });
};

/**
 * create order quotation
 * @param {NewOrder} payload for trip Filter
 * @param {string} bus_company - bus company
 *  @returns {Promise<AxiosResponse<CreateOrderResponse>>} - axios response
 */
const createQuotation = (
  payload: NewOrder,
  bus_company: string
): Promise<AxiosResponse<CreateOrderResponse>> => {
  return useAxiosTEQ.post(`${HTTP_ORDER.CREATE_ORDER}`, payload, {
    params: {
      bus_company,
      org_ids: JSON.stringify([payload.org_id.toString()]),
    },
  });
};

/**
 * create order query
 * @param {NewOrder} payload for trip Filter
 * @param {string} bus_company - bus company
 *  @returns {Promise<AxiosResponse<OrderResponse>>} - axios response
 */
const createQuery = (
  payload: NewOrder,
  bus_company: string
): Promise<AxiosResponse<CreateOrderResponse>> => {
  return useAxiosTEQ.post(`${HTTP_ORDER.CREATE_QUERY}`, payload, {
    params: {
      bus_company,
      org_ids: JSON.stringify([payload.org_id.toString()]),
    },
  });
};

const rejectOrderApi = (
  dbOrderId: number,
  bus_company: string,
  org_ids: (string | number)[]
): Promise<AxiosResponse> => {
  return useAxiosTEQ.post(
    `${HTTP_ORDER.REJECT_ORDER}/${dbOrderId}`,
    {},
    {
      params: {
        bus_company,
        org_ids: JSON.stringify([org_ids.toString()]),
      },
    }
  );
};

export {
  fetchOrderList,
  fetchOrderCount,
  acceptOrder,
  createQuotation,
  createQuery,
  rejectOrderApi,
};

import { createWebHistory, createRouter, RouteRecordRaw } from "vue-router";
import { useLoaderStore } from "../stores";
import { scrollToHeight } from "../utils/route.utils.ts";
import authRoutes from "../router/modules/auth.ts";
import tripDetailsRoutes from "@/router/modules/tripdetails.ts";
import {
  isAuthenticated,
  manageLoggedUserRedirection,
  manageOrganizationSelection,
  routePermissionCheck,
} from "./guard/RouteGuard.ts";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/dashboard",
    name: "dashboard",
    redirect: "/active-trips",
    beforeEnter: [isAuthenticated, manageOrganizationSelection],
    component: () => import("../layouts/CpLayout.vue"),
    children: [
      {
        path: "/orders",
        name: "orders",
        component: () => import("../views/order/OrderView.vue"),
      },
      {
        path: "/active-trips",
        name: "active_trips",
        component: () => import("../views/trip/active-trip.vue"),
      },
      {
        path: "/select-organization",
        name: "select_organization",
        component: () => import("../views/user/UserOrganization.vue"),
      },
      {
        path: "/upcoming-trips",
        name: "upcoming-trips",
        component: () => import("../views/trip/upcoming-trip.vue"),
      },
      {
        path: "/all-trips",
        name: "all-trips",
        component: () => import("../views/trip/AllTrip.vue"),
      },
      {
        path: "/organizations",
        name: "organizations",
        redirect: "/organizations/structure",
        component: () => import("../views/organization/Organizations.vue"),
        children: [
          {
            name: "structure",
            path: "structure",

            component: () =>
              import(
                "@/components/modules/organization/OrganizationStructure.vue"
              ),
          },
          {
            name: "cp-internal-user",
            path: "internal-user",
            component: () =>
              import(
                "@/components/modules/organization/OrganizationUserAccess.vue"
              ),
          },
          {
            name: "cp-external-contact",
            path: "external-contact",
            component: () =>
              import(
                "@/components/modules/organization/OrganizationExternalContact.vue"
              ),
          },
        ],
      },
      {
        name: "create_query",
        path: "/create-query",
        beforeEnter: [routePermissionCheck],
        meta: { requiresRouteAuthorization: true }, // Add your route meta data
        component: () => import("@/components/modules/order/AddNewOrder.vue"),
      },
      {
        name: "create_order",
        path: "/create-order",
        beforeEnter: [routePermissionCheck],
        meta: { requiresRouteAuthorization: true }, // Add your route meta data
        component: () => import("../views/order/DirectOrderAdd.vue"),
      },
      {
        name: "edit_order",
        path: "/edit-order",
        beforeEnter: [routePermissionCheck],
        meta: { requiresRouteAuthorization: true }, // Add your route meta data
        component: () => import("../views/order/DirectOrderEdit.vue"),
      },
      ...tripDetailsRoutes,
    ],
  },
  {
    path: "/",
    name: "home",
    redirect: "/auth/signin",
    beforeEnter: [manageLoggedUserRedirection],
    component: () => import("../layouts/AuthLayout.vue"),
    children: [...authRoutes],
  },
  {
    path: "/invitation-link-verification",
    component: () => import("../views/auth/InvitationVerification.vue"),
  },
  {
    path: "/custom-error/:tag",
    name: "error",
    component: () => import("../views/ErrorView.vue"),
  },
  {
    path: "/:pathMatch(.*)*",
    component: () =>
      import(/* webpackChunkName: "error" */ "../views/ErrorView.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes,
});

/**
 * @description This is a global guard that will run before every route change
 */
router.beforeEach(async (to, from, next) => {
  if (to.path !== from.path) {
    const loader = useLoaderStore();
    loader.changeLoadingStatus({ isLoading: true });
    scrollToHeight(-1);
  }
  next();
});

router.afterEach((to, from) => {
  if (to.path !== from.path) {
    // This section will be replaced with an improved solution in middleware. For now, we're adding this as a temporary measure.
    setTimeout(() => {
      const loader = useLoaderStore();
      loader.changeLoadingStatus({ isLoading: false });
    }, 500);
  }
});
export default router;

import { useAxiosCP } from "@/services/axios";
import { HTTP_AUTH } from "@/services/enums/httpEnum.ts";
import { CreateProfilePayload } from "@/components/modules/auth/types.ts";

/**
 * Verify User
 *
 * @returns {Promise}
 * @param payload
 */
const fetchVerifyUser = (payload) => {
  return useAxiosCP.post(HTTP_AUTH.VERIFY_USER, payload, {
    headers: { "X-Api-Key": import.meta.env.VITE_APP_API_KEY_CP },
  });
};

/**
 * Create profile api.
 *
 * @param payload
 * @returns
 */
const createProfile = (payload: CreateProfilePayload) => {
  return useAxiosCP.post(HTTP_AUTH.CREATE_PROFILE, payload, {
    headers: { "X-Api-Key": import.meta.env.VITE_APP_API_KEY_CP },
  });
};

export { fetchVerifyUser, createProfile };

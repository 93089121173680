import { TypeUserInfo } from "types/auth/user.type";
import { InternalUser, State } from "./types";
import { OrganizationType, UserAccess } from "@/components/modules/auth/types";
import { CompanyInfo } from "types/common";

export default {
  /**
   * getting user info from getter
   *
   * @returns {User}
   */
  getUserInfo: (state: State): TypeUserInfo => {
    return state.data.user_info;
  },
  /**
   * getting user authentication info
   *
   * @returns {boolean}
   */
  getAuthenticationInfo: (state: State): boolean => {
    return state.isAuthenticated;
  },
  /**
   * getting user fullname
   *
   * @returns {string}
   */
  getFullname: (state: State): string => {
    return `${state.data.user_info.attributes["custom:first_name"]} ${state.data.user_info.attributes["custom:last_name"]}`;
  },
  /**
   * getting user authentication info
   *
   * @returns {boolean}
   */
  getOrganizations: (state: State): OrganizationType[] => {
    return state.data.organizations;
  },
  /**
   * getting user authentication info
   *
   * @returns {boolean}
   */
  getSelectedOrganization: (state: State): string => {
    return state.selectedOrganization;
  },

  /**
   * getting user authentication info
   *
   * @returns {boolean}
   */
  getSelectedCompanyInfo: (state: State): CompanyInfo => {
    return state.selectedCompanyInfo;
  },

  /**
   * getting user access
   *
   * @returns {UserAccess} - user access
   */
  getUserAccess: (state: State): UserAccess => {
    return state.data.userAccess;
  },

  /**
   * getting user access org ids.
   * @param {State} state - state
   * @returns {number[]} - returns an array of org ids
   */
  getUserAccessOrgIds: (state: State): (string | number)[] => {
    return state.data.userAccessOrgIds;
  },

  /**
   * getting internal user info
   *
   * @returns {InternalUser} -internal User
   */
  getInternalUserList: (state: State): InternalUser[] => {
    return state.data.internalUserList;
  },
};

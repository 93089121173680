import { State } from "./types";

export default {
  /**
   * getting details info for  orders
   *
   * @returns {State} - return state for  orders
   */
  getOrderInfo: (state: State): State => {
    return state;
  },
};

import { localStorageGetItem } from "@/core/localStorage/LocalStorage";
import { InternalUser, State } from "./types";
import { UserAccess } from "@/components/modules/auth/types";

/*
 * define state here
 */
export default (): State => ({
  data: {
    user_info: {
      username: "",
      id: "",
      attributes: {
        sub: "",
        identities: "",
        email_verified: false,
        name: "",
        phone_number_verified: false,
        isProfileComplete: "",
        email: "",
      },
    },
    organizations: [],
    userAccess: {} as UserAccess,
    userAccessOrgIds: [],
    internalUserList: [] as InternalUser[],
  },
  selectedOrganization: localStorageGetItem("selected_organization") as string,
  isAuthenticated: false,
  isLoading: true,
  shouldAuthenticationBegin: false,
  selectedCompanyInfo: {
    logo: "/logo.svg",
    timezone: "",
    default_pause_time: 0,
    leg_percentage: 0,
    max_departure: 0,
  },
  assignedRole: "",
});

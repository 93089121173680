import { App } from "vue";
import Menu from "primevue/menu";
import Menubar from "primevue/menubar";
import Dropdown from "primevue/dropdown";
import TabPanel from "primevue/tabpanel";
import TabView from "primevue/tabview";
import TabMenu from "primevue/tabmenu";
import RadioButton from "primevue/radiobutton";
import SelectButton from "primevue/selectbutton";

type ComponentDictionary = {
  [key: string]: any;
};

const componentDictionary: ComponentDictionary = {
  Menubar,
  Menu,
  Dropdown,
  TabPanel,
  TabView,
  TabMenu,
  RadioButton,
  SelectButton,
};

export default (app: App) => {
  Object.keys(componentDictionary).forEach((key) => {
    app.component(key, componentDictionary[key]);
  });
};

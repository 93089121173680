import en from "./en-US.json";
import no from "./no-NO.json";
import se from "./sv-SE.json";
import da from "./da-DK.json";

import { createI18n } from "vue-i18n";
import { localStorageGetItem } from "@/core/localStorage/LocalStorage";

export const messages = {
  en,
  no,
  se,
  da,
};
const selectedLocale = localStorageGetItem("locale") || "en";

export const i18n = createI18n({
  globalInjection: true,
  fallbackLocale: "en",
  legacy: false,
  locale: selectedLocale,
  messages: messages,
});

export const languages = {
  en: "English",
  no: "Norwegian",
  se: "Swedish",
  da: "Danish",
};

export const flags = {
  en: "/icons/locales/en.svg",
  no: "/icons/locales/no.svg",
  se: "/icons/locales/se.svg",
  da: "/icons/locales/da.svg",
};

export const t = i18n.global.t as any;
export const locale = i18n.global.locale.value as string;

import { State, FirebaseTrip } from "./types";

export default {
  /**
   * getting details info for upcoming trips
   *
   * @returns {State} -return state for upcoming trips
   */
  getTripInfo: (state: State): State => {
    return state;
  },

  getSelectedFirebaseTrip: (state: State): FirebaseTrip | undefined => {
    return state.selectedFirebaseTrip;
  },
};

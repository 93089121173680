const tripDetailsRoutes = [
  {
    path: "/trip-details",
    name: "trip_details",
    component: () => import("@/components/modules/trip-details/index.vue"),
    children: [
      {
        path: "fixed-trip/:id",
        name: "fixed_trips",
        component: () =>
          import("@/components/modules/trip-details/FixedTrip.vue"),
      },
      {
        path: "normal-trip/:id",
        name: "normal_trips",
        component: () =>
          import("@/components/modules/trip-details/NormalTrip.vue"),
      },
    ],
  },
];

export default tripDetailsRoutes;

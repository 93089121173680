import { defineStore } from "pinia";
import state from "./direct-order.state";
import getters from "./direct-order.getters";
import actions from "./direct-order.actions";

const useDirectOrderStore = defineStore("direct_order", {
  state,
  getters,
  actions,
});

export default useDirectOrderStore;

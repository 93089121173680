import { State, DirectOrderTrip } from "./types";

export default {
  /**
   * Retrieves all trip values from the `directOrder` object.
   *
   * This method returns an array of all `DirectOrderTrip` objects stored in the
   * `directOrder` object within the store's state.
   * @param {State} state - The state of the store.
   * @returns {DirectOrderTrip[]} - An array of all trips in the `directOrder` object.
   */
  getOrderFormValues: (state: State): DirectOrderTrip[] => {
    return Object.values(state.directOrder?.trip);
  },
  getCancelTrips: (state: State): number[] => {
    return state.directOrder.cancel_trips;
  },
};

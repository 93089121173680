import { MENU_PERMISSION } from "@/services/enums/commonEnum";
import { useUserStore } from "@/stores";
import { computed, watchEffect } from "vue";

const userStore = () => useUserStore();

export const auth = {
  isAuthenticated: computed(() => userStore().getAuthenticationInfo),
  loading: computed(() => userStore().$state.isLoading),
  assignedRole: computed(() => userStore().$state.assignedRole),
};
export const userAccess = {
  isOrganizationSelected: computed(
    () => userStore().$state.selectedOrganization
  ),
};
/**
 *
 *check id user login
 * @param to
 * @param from
 * @param next
 */
export const manageLoggedUserRedirection = (to, from, next) => {
  const { isAuthenticated, loading } = auth;

  const verify = () => {
    if (isAuthenticated.value) {
      return next({ name: "dashboard" });
    } else {
      return next();
    }
  };

  if (!loading.value) {
    return verify();
  }

  // Watch for the loading property to change before we check isAuthenticated
  watchEffect(() => {
    if (loading.value === false) {
      return verify();
    }
  });
};
/**
 *
 *check is organization selected or not
 * @param to
 * @param from
 * @param next
 */
export const manageOrganizationSelection = (to, from, next) => {
  const { isAuthenticated, loading } = auth;
  const { isOrganizationSelected } = userAccess;

  const verify = () => {
    if (
      isAuthenticated.value &&
      !isOrganizationSelected.value?.length &&
      to.path != "/select-organization"
    ) {
      return next({ path: "/select-organization" });
    } else {
      return next();
    }
  };

  if (!loading.value) {
    return verify();
  }

  // Watch for the loading property to change before we check isAuthenticated
  watchEffect(() => {
    if (loading.value === false) {
      return verify();
    }
  });
};

/**
 *
 * managing authentication
 * @param to
 * @param from
 * @param next
 */
export const isAuthenticated = (to, from, next) => {
  const { loading, isAuthenticated } = auth;

  const verify = () => {
    if (!isAuthenticated.value && to.path !== "/auth/signin") {
      return next({ path: "/auth/signin" });
    } else {
      return next();
    }
  };

  if (!loading.value) {
    return verify();
  }

  // Watch for the loading property to change before we check isAuthenticated
  watchEffect(() => {
    if (loading.value === false) {
      return verify();
    }
  });
};

export const routePermissionCheck = (to, from, next) => {
  const { assignedRole } = auth; // Assuming auth is a ref object

  watchEffect(() => {
    if (assignedRole.value) {
      if (!permissionCheck(to.name)) {
        next({
          name: "error",
          params: { tag: "permission-denied" },
        });
      } else {
        next();
      }
    }
  });
};

export const permissionCheck = (routeName) => {
  const { assignedRole } = auth; // Assuming auth is a ref object

  const requiredPermissions = ["*.*", `${routeName}.*`];

  return requiredPermissions.some((permission) =>
    MENU_PERMISSION[assignedRole.value].includes(permission)
  );
};

import { TripResponse } from "../../../stores/trip/types";

export const mockTripData: TripResponse = {
  data: {
    count: 0,
    next: 0,
    previous: 0,
    results: [],
  },
  status: "success",
  message: "success",
  code: 200,
};

import {
  fetchAllTrips,
  fetchUpcomingTrips,
  fetchFixedTripDetails,
  fetchNormalTripDetails,
  cancelTrip,
} from "@/services/api/trips/tripApi";
import {
  FixedTrip,
  NormalTrip,
  StoreContext,
  Trip,
  TypeTripFilter,
} from "./types";
import useLoaderStore from "../loader/loader.store";
import useUserStore from "../user/user.store.ts";
import { RESPONSE_TYPE } from "@/services/enums/httpEnum";
import { splitAndGetFirstItem } from "@/utils/utils.ts";
import { AxiosResponse } from "axios";

export default {
  /**
   *
   * @param {StoreContext} this -store context
   * @param {TypeTripFilter} payload -function payload for filtering trips
   * @returns {Promise<Trip[]>} - returning all thr trip in the component but may be we it will not required we could send something else there
   */
  async fetchUpcomingTrips(
    this: StoreContext,
    payload: TypeTripFilter
  ): Promise<Trip[]> {
    this.$reset();
    const loader = useLoaderStore();
    loader.changeLoadingStatus({ isLoading: true });
    return await fetchUpcomingTrips(payload)
      .then((res) => {
        loader.changeLoadingStatus({ isLoading: false });
        const response = res.data;
        if (res.status == 200 && response?.status !== RESPONSE_TYPE.SUCCESS)
          throw Error("API error");

        /*
         * Need to change result into trips from backend
         */
        this.$state.results = response.data.results;
        this.$state.count = response.data.count;
        this.$state.next = response.data.next;
        this.$state.previous = response.data.previous;
        return response.data.results;
      })
      .catch((error) => {
        loader.changeLoadingStatus({ isLoading: false });
        throw error;
      });
  },
  /**
   *
   * @param {StoreContext} this -store context
   * @param {TypeTripFilter} payload -function payload for filtering trips
   * @returns {Promise<Trip[]>} - returning all thr trip in the component but may be we it will not required we could send something else there
   */
  async fetchAllTrips(
    this: StoreContext,
    payload: TypeTripFilter
  ): Promise<Trip[]> {
    this.$reset();
    const loader = useLoaderStore();
    loader.changeLoadingStatus({ isLoading: true });
    return fetchAllTrips(payload)
      .then((res) => {
        loader.changeLoadingStatus({ isLoading: false });
        const response = res.data;
        if (res.status == 200 && response?.status !== RESPONSE_TYPE.SUCCESS)
          throw Error("API error");

        /*
         * Need to change result into trips from backend
         */
        this.$state.results = response.data.results;
        this.$state.count = response.data.count;
        this.$state.next = response.data.next;
        this.$state.previous = response.data.previous;
        return response.data.results;
      })
      .catch((error) => {
        loader.changeLoadingStatus({ isLoading: false });
        throw error;
      });
  },

  /**
   *
   * @param {StoreContext} this -store context
   * @param id -function payload for filtering trips
   * @param org_id
   * @returns {Promise<FixedTrip>} - return fixed trip details
   */
  async getFixedTripDetails(
    this: StoreContext,
    id: number,
    org_id: string
  ): Promise<FixedTrip> {
    const loader = useLoaderStore();
    const user = useUserStore();
    loader.changeLoadingStatus({ isLoading: true });
    return fetchFixedTripDetails(id, {
      bus_company: splitAndGetFirstItem(user.selectedOrganization, "#"),
      org_ids: JSON.stringify([org_id]),
    })
      .then((res) => {
        loader.changeLoadingStatus({ isLoading: false });
        const response = res.data;
        if (res.status == 200 && response?.status !== RESPONSE_TYPE.SUCCESS)
          throw Error("API error");
        return response.data;
      })
      .catch((error) => {
        loader.changeLoadingStatus({ isLoading: false });
        throw error;
      });
  },

  /**
   *
   * @param id -function payload for filtering trips
   * @param org_id
   * @returns {Promise<NormalTrip>} - return fixed trip details
   */
  async getNormalTripDetails(
    this: StoreContext,
    id: number,
    org_id: string
  ): Promise<NormalTrip> {
    const loader = useLoaderStore();
    const user = useUserStore();
    loader.changeLoadingStatus({ isLoading: true });
    return fetchNormalTripDetails(id, {
      bus_company: splitAndGetFirstItem(user.selectedOrganization, "#"),
      org_ids: JSON.stringify([org_id]),
    })
      .then((res) => {
        loader.changeLoadingStatus({ isLoading: false });
        const response = res.data;
        if (res.status == 200 && response?.status !== RESPONSE_TYPE.SUCCESS)
          throw Error("API error");
        return response.data;
      })
      .catch((error) => {
        loader.changeLoadingStatus({ isLoading: false });
        throw error;
      });
  },

  /**
   * Cancel an trip.
   * @param {StoreContext} this -store context
   * @param {number} trip_id - id of the trip
   * @param {string} trip_type - id of the trip type
   * @param {string} org_id - org id of the trip
   * @returns {Promise<AxiosResponse>} - axios response
   */
  async cancelTrip(
    this: StoreContext,
    trip_id: number,
    trip_type: string,
    org_id: number
  ): Promise<AxiosResponse> {
    const user = useUserStore();
    const loader = useLoaderStore();
    loader.changeLoadingStatus({ isLoading: true });
    const bus_company = splitAndGetFirstItem(user.selectedOrganization, "#");
    return cancelTrip(trip_id, trip_type, bus_company, org_id)
      .then((res) => {
        loader.changeLoadingStatus({ isLoading: false });
        const response = res.data;
        if (res.status == 200 && response?.status !== RESPONSE_TYPE.SUCCESS)
          throw Error("API error");
        return res;
      })
      .catch((error) => {
        loader.changeLoadingStatus({ isLoading: false });
        throw error;
      });
  },
};

import { State } from "@/stores/auth/types.ts";
import { UserProfile } from "@/components/modules/auth/types.ts";

export default {
  /*
   * getting the loader
   */
  getUserProfile(state: State): UserProfile {
    return state.userProfile;
  },
};

// import { EtagService } from "@/core/http/EtagService";
import { handleErrorResponse } from "@/core/http/axios/handleResponse";
import axios, {
  AxiosError,
  AxiosResponse,
  InternalAxiosRequestConfig,
} from "axios";
import { getAuthJWTToken } from "../auth/auth.service";
import { useUserStore } from "@/stores";

const commonHeader = {
  Accept: "application/json",
  "Content-Type": "application/json",
};
/*
 * creating a axios instance for CP
 */
export const useAxiosCP = axios.create({
  baseURL: import.meta.env.VITE_APP_ROOT_CP_API,
  headers: {
    ...commonHeader,
  },
});
/*
 * creating a axios instance for TEQ
 */
export const useAxiosTEQ = axios.create({
  baseURL: import.meta.env.VITE_APP_ROOT_TEQ_API,
  headers: {
    ...commonHeader,
  },
});

/**
 * Interceptor handler for Axios requests,
 * @param   {AxiosRequestConfig} config - request config
 * @returns {AxiosRequestConfig}        - request config
 */
const onRequest = async (
  config: InternalAxiosRequestConfig
): Promise<InternalAxiosRequestConfig> => {
  if (config.baseURL === import.meta.env.VITE_APP_ROOT_TEQ_API) {
    config.headers["root-org"] = useUserStore().selectedOrganization;
  }
  try {
    const res = await getAuthJWTToken();
    config.headers.Authorization = `Bearer ${res}`;
  } catch (error) {
    // Handle token fetch error, if necessary
    console.error("Error fetching token:", error);
  }

  return config;

  /*
   * setting etag header
   * We dont have this functionality in our BE, In future may be we will work.
   */
  // EtagService.setEtagHeader(config);
};

/**
 * Handles any http request error
 * @param   {AxiosError} error    - axios error
 * @returns {Promise<AxiosError>} - returns a promise of axios error
 */
const onRequestError = (error: AxiosError): Promise<AxiosError> => {
  return Promise.reject(error);
};

/**
 * Interceptor handler for successfully Axios responses
 * @param   {AxiosResponse} response  - incoming axios response
 * @returns {AxiosResponse}           - axios response (pass thru)
 */
const onResponse = (response: AxiosResponse): AxiosResponse => {
  /*
   * Currently not in use
   *
   * handling success response
   *
   */
  // handleSuccessResponse(response);
  /*
   * storing etag header
   * We dont have this functionality in our BE, In future may be we will work.
   */
  // EtagService.storeEtag(response);
  return response;
};

/**
 * Interceptor handler for failed Axios responses,
 * as defined by `validateStatus` in axios config
 * (see [documentation](https://axios-http.com/docs/handling_errors))
 * @param   {AxiosError} error    - axios error
 * @returns {Promise<AxiosError>} - returns a promise of type axios error
 */
const onResponseError = (error: AxiosError): Promise<AxiosError> => {
  /*
   * handling error response
   */
  handleErrorResponse(error);
  return Promise.reject(error);
};

/**
 * CP
 *
 * Axios request interceptor, delegating logic to dedicated handlers
 */
useAxiosCP.interceptors.request.use(onRequest, onRequestError);
/**
 * CP
 *
 * Axios response interceptor, delegating logic to dedicated handlers
 */
useAxiosCP.interceptors.response.use(onResponse, onResponseError);
/**
 * TEQ
 *
 * Axios request interceptor, delegating logic to dedicated handlers
 */
useAxiosTEQ.interceptors.request.use(onRequest, onRequestError);

/**
 * TEQ
 *
 * Axios response interceptor, delegating logic to dedicated handlers
 */
useAxiosTEQ.interceptors.response.use(onResponse, onResponseError);

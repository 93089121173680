import { signIn, getUser, signOut } from "@/services/auth/auth.service";
import { showToast } from "@/services/toast/toast.service";
import useLoaderStore from "../loader/loader.store";
import { InternalUser, StoreContext } from "./types";
import {
  OrganizationType,
  UserAccess,
  UserSignIn,
} from "@/components/modules/auth/types";
import { GeneralError } from "types/errors/errors.type";
import {
  getOrganizations,
  getUserAccess,
  revokeUserInvitation,
  sendUserInvitation,
  getInternalUser,
  fetchCompanyInfo,
} from "@/services/api/user/userApi";
import { RESPONSE_TYPE } from "@/services/enums/httpEnum";
import UriController from "@/controllers/common/uriController";
import { splitAndGetFirstItem } from "@/utils/utils.ts";

export default {
  /**
   * Attempt to sign in a user
   * @param {UserSignIn} signInData
   * @param {Function} successCallback
   * @param {Function} errorCallback
   */
  signInAction(this: StoreContext, signInData: UserSignIn): Promise<Object> {
    const loader = useLoaderStore();
    loader.changeLoadingStatus({ isLoading: true });

    return signIn(signInData.email, signInData.password)
      .then((res) => {
        loader.changeLoadingStatus({ isLoading: false });
        return res;
      })
      .catch((err) => {
        loader.changeLoadingStatus({ isLoading: false });
        throw err;
      });
  },

  /**
   *
   * @param {this} StoreContext -its contains store context
   */
  async signOutAction(this: StoreContext) {
    const loader = useLoaderStore();
    loader.changeLoadingStatus({ isLoading: true });
    this.$reset();
    try {
      await signOut().then(() => {
        loader.changeLoadingStatus({ isLoading: false });
        this.$state.isLoading = false;
      });
    } catch (err) {
      this.$state.isLoading = false;
      loader.changeLoadingStatus({ isLoading: false });
      showToast("error", (err as GeneralError).message, 1800, "top-right");
      throw err;
    }
  },

  /**
   * fetching user info after login
   */
  async fetchingUserInfo(this: StoreContext): Promise<object> {
    const loader = useLoaderStore();
    loader.changeLoadingStatus({ isLoading: true });
    return getUser()
      .then((result) => {
        loader.changeLoadingStatus({ isLoading: false });
        if (result) {
          this.$state.data.user_info = result;
          this.$state.isAuthenticated =
            this.$state.data.user_info.attributes.email_verified === true;
          this.$state.isLoading = false;
          return result;
        } else {
          this.$state.isAuthenticated = false;
          this.$state.isLoading = false;
          throw new Error("Cognito error!");
        }
      })
      .catch((error) => {
        this.$state.isLoading = false;
        loader.changeLoadingStatus({ isLoading: false });
        throw new Error(error.message);
      });
  },

  /**
   * fetching user organization info after login
   */
  async fetchOrganizations(this: StoreContext): Promise<OrganizationType[]> {
    const loader = useLoaderStore();
    loader.changeLoadingStatus({ isLoading: true });
    const user_id = this.$state.data.user_info.username;
    return getOrganizations(user_id)
      .then((res) => {
        const response = res.data;
        if (response?.status !== RESPONSE_TYPE.SUCCESS)
          throw Error("API error");
        this.$state.data.organizations = response.data;
        return response.data;
      })
      .catch((err) => {
        throw err;
      })
      .finally(() => {
        loader.changeLoadingStatus({ isLoading: false });
      });
  },

  setUserOrganization(this: StoreContext, organization: string): void {
    this.$state.selectedOrganization = organization;
  },

  /**
   * Get user access based on parent organization
   * @param {StoreContext} this - the state
   * @param {ForgotPassword} payload - payload for forgot password
   */
  async fetchUserAccess(this: StoreContext): Promise<UserAccess> {
    const loader = useLoaderStore();
    loader.changeLoadingStatus({ isLoading: true });
    const payload = {
      root_org: this.$state.selectedOrganization,
      user_id: this.$state.data.user_info.username,
    };
    return getUserAccess(payload)
      .then((result) => {
        const response = result.data;
        if (response?.status !== RESPONSE_TYPE.SUCCESS)
          throw Error("API error");
        loader.changeLoadingStatus({ isLoading: false });
        this.$state.data.userAccess = response.data;
        this.$state.assignedRole = response.data.user_access[0].role;

        const userAccessOrgIds = response.data.user_access.map(
          (item) => item["teq_org_id"]
        );

        UriController.setDefaultOrg(userAccessOrgIds);
        this.$state.data.userAccessOrgIds = userAccessOrgIds;
        return response.data;
      })
      .catch((error) => {
        loader.changeLoadingStatus({ isLoading: false });
        throw error;
      });
  },
  /**
   * invite user
   * @param {StoreContext} this - the store
   * @param payload - payload for invitaion
   * @returns Promise<any>
   */
  async userInvite(this: StoreContext, payload: object): Promise<any> {
    const loader = useLoaderStore();
    loader.changeLoadingStatus({ isLoading: true });
    return sendUserInvitation(payload)
      .then((result) => {
        const response = result.data;
        if (response?.status !== RESPONSE_TYPE.SUCCESS)
          throw Error("API error");
        loader.changeLoadingStatus({ isLoading: false });
        return response.message;
      })
      .catch((error) => {
        loader.changeLoadingStatus({ isLoading: false });
        console.log(error);
        throw new Error(error.message);
      });
  },
  /**
   * Get internal user
   * @param {StoreContext} this - the state
   * @param {ForgotPassword} payload - payload for forgot password
   */
  async fetchInternalUserList(this: StoreContext): Promise<InternalUser[]> {
    const loader = useLoaderStore();
    loader.changeLoadingStatus({ isLoading: true });
    const payload = {
      root_org: this.$state.selectedOrganization,
    };
    return getInternalUser(payload)
      .then((result) => {
        const response = result.data;
        if (response?.status !== RESPONSE_TYPE.SUCCESS)
          throw Error("API error");
        loader.changeLoadingStatus({ isLoading: false });
        this.$state.data.internalUserList = response.data;
        return response.data;
      })
      .catch((error) => {
        loader.changeLoadingStatus({ isLoading: false });
        throw error;
      });
  },

  /**
   * invite user
   * @param {StoreContext} this - the store
   * @param payload - payload for invitaion
   * @param email
   * @returns Promise<any>
   */
  async revokeUserInvite(this: StoreContext, email: string): Promise<any> {
    const loader = useLoaderStore();
    loader.changeLoadingStatus({ isLoading: true });

    const payload = {
      root_org: this.$state.selectedOrganization,
      email: email,
    };
    return revokeUserInvitation(payload)
      .then((result) => {
        const response = result.data;
        if (response?.status !== RESPONSE_TYPE.SUCCESS)
          throw Error("API error");
        loader.changeLoadingStatus({ isLoading: false });
        this.fetchInternalUserList();
        return response.message;
      })
      .catch((error) => {
        loader.changeLoadingStatus({ isLoading: false });
        throw error;
      });
  },

  async getCompanyInfo(this: StoreContext): Promise<any> {
    const loader = useLoaderStore();
    loader.changeLoadingStatus({ isLoading: true });
    const bus_company = splitAndGetFirstItem(
      this.$state.selectedOrganization,
      "#"
    );
    return fetchCompanyInfo(bus_company)
      .then((result) => {
        const response = result.data;
        if (response?.status !== RESPONSE_TYPE.SUCCESS)
          throw Error("API error");
        if (response.data?.logo)
          this.$state.selectedCompanyInfo = response.data;
        loader.changeLoadingStatus({ isLoading: false });
        return response.message;
      })
      .catch((error) => {
        loader.changeLoadingStatus({ isLoading: false });
        throw error;
      });
  },
};

import {
  ForgotPassword,
  VerifyAttributes,
  CreateProfilePayload,
} from "@/components/modules/auth/types";
import { StoreContext } from "./types";
import { createProfile, fetchVerifyUser } from "@/services/api/auth/authApi.ts";
import { useLoaderStore } from "@/stores";
import {
  forgotPassword,
  forgotPasswordSubmit,
} from "@/services/auth/auth.service";
import { RESPONSE_TYPE } from "@/services/enums/httpEnum";

export default {
  /**
   * Change loading status action
   * @param payload
   */
  async verifyUser(
    this: StoreContext,
    payload: VerifyAttributes
  ): Promise<Object> {
    const loader = useLoaderStore();
    loader.changeLoadingStatus({ isLoading: true });
    return fetchVerifyUser(payload)
      .then((res) => {
        const response = res.data;
        if (response?.status !== RESPONSE_TYPE.SUCCESS)
          throw Error("API error");
        this.$state.userProfile = response.data;
        return response.data;
      })
      .catch((err) => {
        throw err;
      })
      .finally(() => {
        loader.changeLoadingStatus({ isLoading: false });
      });
  },

  /**
   * send otp to verify a user attribute
   *
   * @param {StoreContext} this - the state
   * @param {ForgotPassword} payload - payload for forgot password
   */
  async handleForgotPasswordSubmit(
    this: StoreContext,
    payload: ForgotPassword
  ): Promise<string> {
    const loader = useLoaderStore();
    loader.changeLoadingStatus({ isLoading: true });
    return forgotPasswordSubmit(payload)
      .then((result) => {
        loader.changeLoadingStatus({ isLoading: false });
        return result;
      })
      .catch((error) => {
        loader.changeLoadingStatus({ isLoading: false });
        throw error;
      });
  },

  /**
   * send otp to verify a user attribute
   *
   * @param {StoreContext} this - the state
   * @param {string} userName - the username of users
   */
  async handleForgotPasswordCode(
    this: StoreContext,
    userName: string
  ): Promise<object> {
    const loader = useLoaderStore();
    return forgotPassword(userName)
      .then((res) => {
        loader.changeLoadingStatus({ isLoading: true });
        return res;
      })
      .catch((err) => {
        throw err;
      });
  },

  /**
   * Change loading status action
   * @param payload
   */
  async createProfileAction(
    this: StoreContext,
    payload: CreateProfilePayload
  ): Promise<object> {
    const loader = useLoaderStore();
    loader.changeLoadingStatus({ isLoading: true });
    return createProfile(payload)
      .then((res) => {
        const response = res.data;
        if (response?.status !== RESPONSE_TYPE.SUCCESS)
          throw Error("API error");
        return response;
      })
      .catch((err) => {
        throw err;
      })
      .finally(() => {
        loader.changeLoadingStatus({ isLoading: false });
      });
  },
};

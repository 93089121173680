import { useAxiosCP, useAxiosTEQ } from "@/services/axios";
import { HTTP_USER } from "@/services/enums/httpEnum";
import {
  EditInviteUserResponse,
  InternalUserResponse,
  InviteUserPayload,
  OrganizationResponse,
  UserAccessResponse,
} from "@/stores/user/types";
import { AxiosResponse } from "axios";

/**
 *  Get user organization for organization select ui.
 * @returns {Promise}
 * @param user_id
 * @param payload
 */
const getOrganizations = (
  user_id
): Promise<AxiosResponse<OrganizationResponse>> => {
  return useAxiosCP.get(`${HTTP_USER.GET_ORGANIZATIONS}`, {
    headers: { "X-Api-Key": import.meta.env.VITE_APP_API_KEY_CP },
    params: {
      user_id,
    },
  });
};

/**
 * Get user internal user list.
 * @returns {Promise}
 * @param payload
 */
const getInternalUser = (
  payload
): Promise<AxiosResponse<InternalUserResponse>> => {
  return useAxiosCP.post(`${HTTP_USER.GET_INTERNAL_USER_LIST}`, payload);
};
/**
 * Get user organization list.
 * @returns {Promise}
 * @param payload
 */
const getUserAccess = (payload): Promise<AxiosResponse<UserAccessResponse>> => {
  return useAxiosCP.post(`${HTTP_USER.GET_USER_ACCESS}`, payload);
};

/**
 * send user invitation
 * @param payload
 */

const sendUserInvitation = (payload) => {
  return useAxiosCP.post(HTTP_USER.SENT_USER_INVITATION, payload);
};

/**
 * revoke user invitation
 * @param payload
 */

const revokeUserInvitation = (payload): Promise<AxiosResponse<any>> => {
  return useAxiosCP.post(HTTP_USER.REVOKE_USER_INVITATION, payload);
};

const getUserForEdit = (
  payload: InviteUserPayload
): Promise<AxiosResponse<EditInviteUserResponse>> => {
  return useAxiosCP.post(HTTP_USER.GET_USER_FOR_EDIT, payload);
};

const editUserAccess = (payload: InviteUserPayload) => {
  return useAxiosCP.post(HTTP_USER.EDIT_USER_ACCESS, payload);
};

const deleteUserAccess = (payload: InviteUserPayload) => {
  return useAxiosCP.post(HTTP_USER.DELETE_USER_ACCESS, payload);
};

/**
 * Get company info.
 * @returns {Promise}
 * @param bus_company
 */
const fetchCompanyInfo = (bus_company: string): Promise<AxiosResponse<any>> => {
  return useAxiosTEQ.get(HTTP_USER.COMPANY_INFO, {
    headers: {
      "X-Api-Key": import.meta.env.VITE_APP_API_KEY_TEQ,
      showToast: true,
    },
    params: {
      bus_company,
    },
  });
};

export {
  getOrganizations,
  getUserAccess,
  sendUserInvitation,
  revokeUserInvitation,
  getInternalUser,
  getUserForEdit,
  editUserAccess,
  deleteUserAccess,
  fetchCompanyInfo,
};

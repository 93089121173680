import { defineStore } from "pinia";
import state from "./order.state";
import actions from "./order.action";
import getters from "./order.getters";

const useOrderStore = defineStore("order", {
  state,
  actions,
  getters,
});

export default useOrderStore;

import router from "../router";

export const routerPushTagQuery = (
  name: string,
  tag: string,
  queryObj: object
) => {
  router.push({ name, params: { tag }, query: { ...queryObj } });
};
export const routerPushQueryWithPath = (path: string, queryObj: object) => {
  router.push({ path, query: { ...queryObj } });
};
export const routerPushTag = (name: string, tag: string, tagName = "tag") => {
  router.push({ name: name, params: { [tagName]: tag } });
};
export const routerPushMultipleTag = (name: string, paramObj: object) => {
  router.push({ name: name, params: { ...paramObj } });
};
export const routerResolveWithTagQuery = (
  name: string,
  tag: string,
  tagName = "tag",
  queryObj: object = {}
) => {
  return router.resolve({
    name: name,
    params: { [tagName]: tag },
    query: { ...queryObj },
  }).fullPath;
};
export const routerPush = (name: string) => {
  router.push({ name: name });
};
export const routerPushHash = (path: string) => {
  router.push(path);
};
export const goBack = () => {
  router.back();
};

export const scrollToHeight = (direction: number) => {
  window.scrollTo(0, direction * document.body.scrollHeight);
};

import { State } from "./types";
/*
 * define state here
 */
export default (): State => ({
  results: [],
  count: 0, //total rows
  next: null,
  previous: null,
  selectedFirebaseTrip: {
    trip_id: "",
    partner_id: -1,
  },
});

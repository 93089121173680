import { State } from "./types";
import { ContactPersonType } from "@/stores/order/types.ts";
/*
 * define state here
 */
export default (): State => ({
  directOrder: {
    trip: {},
    org_id: "",
    timezone: "",
    order_id: "",
    gen_oid: "",
    contact_person: {} as ContactPersonType,
    editedTripIds: [],
    cancel_trips: [],
  },
  errors: {},
});
